
import { Vue, Component, Watch } from "vue-property-decorator";
import { AlmoxarifadoService } from "@/core/services/almoxarifado";
import { Almoxarifado } from "@/core/models/almoxarifado";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";

@Component
export default class ListaAlmoxarifado extends mixins(Vue, ListPage) {
  item = new Almoxarifado();
  service = new AlmoxarifadoService();

  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;

  public titulo: string = "Almoxarifado";
  public subTitulo: string = "tipos de almoxarifados cadastrados no Sistema";
   
 
  options: any = {
    itemsPerPage: 15,
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
  ];

  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc} = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
       (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
      }).finally(() => (this.loading = false));
  }

  Editar(item: Almoxarifado) {
    this.service.ObterPorId(item.id, "Empreendimento, Itens").then((res) => {
      this.item = res.data;
      this.dialogCadastro = true;
    });
  }

  Novo() {
    this.item = new Almoxarifado();
    this.dialogCadastro = true; 
  }
  
  mounted(){
   // this.Atualizar();
  }
}
